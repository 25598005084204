.view-payment-method ion-toolbar > ion-buttons {
  position: absolute;
  left: 0.25rem;
}
.view-payment-method ion-toolbar > ion-title {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}
.view-payment-method ion-title > ion-img {
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-payment-method ion-title > ion-skeleton-text {
  --border-radius: 0.5rem;
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-payment-method ion-text > h2 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-top: 0;
}
.view-payment-method .dashed {
  background-image: linear-gradient(to right, var(--ion-color-medium) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 8px 1px;
  margin-block: 1.5rem;
}
.view-payment-method .total > span {
  color: var(--ion-color-medium);
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.25rem;
  margin-top: 1.25rem;
}
.view-payment-method .total > h3 {
  color: var(--ion-color-dark);
  font-weight: 700;
}
.view-payment-method ion-item {
  --background-hover: transparent;
  --ripple-color: transparent;
}
.view-payment-method ion-avatar {
  height: 6rem;
  width: 6rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 1px -8px, rgb(0 0 0 / 14%) 0px 1px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 4px 0px;
}
.view-payment-method ion-radio::part(container) {
  height: 1.5rem;
  width: 1.5rem;
  border: 2px solid var(--ion-color-medium);
}
.view-payment-method ion-radio::part(mark) {
  background: none;
}
.view-payment-method ion-radio.radio-checked::part(container) {
  border: 0.375rem solid var(--ion-color-dark);
}
.view-payment-method ion-label > ion-skeleton-text {
  --border-radius: 0.5rem;
  height: 2rem;
  max-width: 75%;
}
.view-payment-method ion-label strong {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}
.view-payment-method ion-img::part(image) {
  object-fit: fill;
}
.view-payment-method .submit ion-button {
  --border-radius: 0.75rem;
  font-size: 1rem;
  min-height: 3rem;
}
@media (min-width: 1024px) {
  .view-payment-method ion-label > ion-skeleton-text {
    max-width: 40%;
  }
}
