.view-not-found ion-toolbar > ion-buttons {
  position: absolute;
  left: 0.25rem;
}
.view-not-found ion-toolbar > ion-title {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}
.view-not-found ion-title > ion-img {
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-not-found ion-title > ion-skeleton-text {
  --border-radius: 0.5rem;
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-not-found ion-content > ion-img {
  height: auto;
  max-width: 3rem;
  position: absolute;
}
.view-not-found ion-content > ion-img:nth-child(1) {
  left: 8%;
  top: 28%;
}
.view-not-found ion-content > ion-img:nth-child(2) {
  left: 20%;
  top: 40%;
}
.view-not-found ion-content > ion-img:nth-child(3) {
  left: 8%;
  top: 52%;
}
.view-not-found ion-content > ion-img:nth-child(4) {
  right: 20%;
  top: 32%;
}
.view-not-found ion-content > ion-img:nth-child(5) {
  right: 8%;
  top: 40%;
}
.view-not-found ion-content > ion-img:nth-child(6) {
  right: 20%;
  top: 48%;
}
.view-not-found .container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.view-not-found .container > ion-img {
  border: 0.25rem dashed var(--ion-color-dark);
  border-radius: 1.5rem;
  height: auto;
  margin: auto;
  max-width: 14rem;
}
.view-not-found .container > h2 {
  font-size: 7rem;
  font-weight: 700;
  margin-bottom: 0;
}
.view-not-found .container > p {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0;
}
@media (max-height: 576px) {
  .view-not-found .container {
    margin-block: 1rem;
    position: static;
    text-align: center;
    transform: translateY(0);
  }
  .view-not-found ion-content > ion-img:nth-child(1) {
    left: 8%;
    top: 8%;
  }
  .view-not-found ion-content > ion-img:nth-child(2) {
    left: 16%;
    top: 40%;
  }
  .view-not-found ion-content > ion-img:nth-child(3) {
    left: 10%;
    top: 72%;
  }
  .view-not-found ion-content > ion-img:nth-child(4) {
    right: 16%;
    top: 16%;
  }
  .view-not-found ion-content > ion-img:nth-child(5) {
    right: 8%;
    top: 48%;
  }
  .view-not-found ion-content > ion-img:nth-child(6) {
    right: 18%;
    top: 72%;
  }
}
@media (max-width: 576px) {
  .view-not-found .container > ion-img {
    border: 2px dashed var(--ion-color-dark);
    border-radius: 1rem;
    max-width: 12rem;
  }
  .view-not-found .container > h2 {
    font-size: 6rem;
  }
  .view-not-found .container > p {
    font-size: 1.25rem;
  }
  .view-not-found ion-content > ion-img:nth-child(1),
  .view-not-found ion-content > ion-img:nth-child(2),
  .view-not-found ion-content > ion-img:nth-child(3),
  .view-not-found ion-content > ion-img:nth-child(4),
  .view-not-found ion-content > ion-img:nth-child(5),
  .view-not-found ion-content > ion-img:nth-child(6) {
    display: none;
  }
}
@media (min-width: 1024px) {
  .view-not-found ion-content > ion-img:nth-child(1) {
    left: 16%;
    top: 16%;
  }
  .view-not-found ion-content > ion-img:nth-child(2) {
    left: 28%;
    top: 36%;
  }
  .view-not-found ion-content > ion-img:nth-child(3) {
    left: 16%;
    top: 56%;
  }
  .view-not-found ion-content > ion-img:nth-child(4) {
    right: 22%;
    top: 24%;
  }
  .view-not-found ion-content > ion-img:nth-child(5) {
    right: 32%;
    top: 44%;
  }
  .view-not-found ion-content > ion-img:nth-child(6) {
    right: 20%;
    top: 64%;
  }
}
