.component-payment-total {
  color: var(--ion-color-dark);
  vertical-align: top;
  width: 100%;
}
.component-payment-total td {
  padding-block: 0.25rem;
  padding-inline: 0.5rem;
}
.component-payment-total .date {
  color: var(--ion-color-medium);
}
.component-payment-total .dashed > td {
  background-image: linear-gradient(to right, var(--ion-color-medium) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: center;
  background-repeat: repeat-x;
  background-size: 8px 1px;
  padding-block: 0.5rem;
}
.component-payment-total .grandtotal > td {
  padding: 0.5rem;
}
