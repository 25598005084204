.view-qr-code ion-toolbar > ion-title {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}
.view-qr-code ion-title > ion-img {
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-qr-code ion-title > ion-skeleton-text {
  --border-radius: 0.5rem;
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-qr-code .container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.view-qr-code .queue {
  align-items: center;
  display: flex;
  justify-content: center;
}
.view-qr-code .queue ion-skeleton-text {
  --border-radius: 0.5rem;
  display: inline;
}
.view-qr-code .queue h2 {
  font-size: 1.75rem;
  font-weight: 700;
  margin-block: 0;
}
.view-qr-code .queue ion-img {
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5rem;
}
.view-qr-code .wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 0.75rem;
}
.view-qr-code .wrapper > ion-skeleton-text {
  --border-radius: 0.25rem;
  margin: 1rem;
  min-height: 15rem;
  width: 15rem;
}
.view-qr-code p {
  display: block;
  line-height: 1.75rem;
}
.view-qr-code ion-card,
ion-modal#modal-qr-code ion-card {
  --border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 8px 2px -16px, rgb(0 0 0 / 14%) 0px 2px 8px 0px, rgb(0 0 0 / 12%) 0px 2px 8px 0px;
}
.view-qr-code ion-button {
  --padding-end: 1rem;
  --padding-start: 1rem;
  --border-radius: 0.75rem;
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  justify-content: center;
  min-height: 3rem;
}
.view-qr-code ion-button ion-icon {
  margin-right: 0.75rem;
}
.view-qr-code ion-button span {
  margin-top: 0.125rem;
}
ion-modal#modal-qr-code {
  --backdrop-opacity: 0.8;
  --border-radius: 1rem;
  --max-width: calc(100% - 2rem);
  text-align: center;
}
ion-modal.modal-both {
  --max-height: 28rem;
}
ion-modal.modal-only {
  --max-height: 24rem;
}
ion-modal#modal-qr-code .queue {
  align-items: center;
  display: flex;
  justify-content: center;
}
ion-modal#modal-qr-code .queue h2 {
  font-size: 1.75rem;
  font-weight: 700;
  margin-block: 0;
}
ion-modal#modal-qr-code .queue ion-img {
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5rem;
}
ion-modal#modal-qr-code h2 {
  font-size: 1.5rem;
  font-weight: 700;
}
ion-modal#modal-qr-code .wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}
@media (min-height: 576px) {
  ion-modal#modal-qr-code ion-content::part(scroll) {
    overflow-y: hidden;
  }
}
@media (min-width: 576px) {
  .view-qr-code .container {
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  ion-modal#modal-qr-code {
    --max-width: 20rem;
  }
  ion-modal.modal-both {
    --max-height: 28rem;
  }
  ion-modal.modal-only {
    --max-height: 24rem;
  }
}
@media (min-width: 768px) {
  .view-qr-code section {
    margin-bottom: 1rem;
  }
  .view-qr-code .wrapper {
    margin-block: 1rem;
  }
  .view-qr-code ion-button {
    --border-radius: 1rem;
    --padding-end: 2rem;
    --padding-start: 2rem;
  }
}