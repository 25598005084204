.view-confirmation-order ion-toolbar > ion-buttons {
  position: absolute;
  left: 0.25rem;
}
.view-confirmation-order ion-toolbar > ion-title {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}
.view-confirmation-order ion-title > ion-img {
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-confirmation-order ion-title > ion-skeleton-text {
  --border-radius: 0.5rem;
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-confirmation-order ion-text > h2 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-inline: 0.5rem;
  margin-top: 0;
}
.view-confirmation-order ion-card {
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 8px 2px -16px, rgb(0 0 0 / 14%) 0px 2px 8px 0px, rgb(0 0 0 / 12%) 0px 2px 8px 0px;
  margin: 0.25rem;
}
.view-confirmation-order ion-card-content {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  padding: 0.75rem;
}
.view-confirmation-order ion-card-content > table + table {
  margin-top: 1rem;
}
.view-confirmation-order ion-label > label {
  color: var(--ion-color-dark);
  display: block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-inline: 0.75rem;
}
.view-confirmation-order ion-input {
  --highlight-color-focused: 0;
  --padding-bottom: 0.75rem;
  --padding-end: 0.75rem;
  --padding-start: 0.75rem;
  --padding-top: 0.75rem;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 1rem;
  color: var(--ion-color-dark);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
  transition: border-color 150ms ease-in-out;
}
.view-confirmation-order ion-input.has-focus {
  border-color: var(--ion-color-dark);
  outline: 0;
}
.view-confirmation-order ion-input[readonly] {
  background-color: transparent;
  border: 0 solid transparent;
  outline: 0;
}
.view-confirmation-order ion-note {
  margin-inline: 0.75rem;
}
.view-confirmation-order .is-required > ion-note {
  display: block;
}
.view-confirmation-order ion-button.promo-code {
  --border-radius: 1rem;
  --box-shadow: rgb(0 0 0 / 12%) 0px 8px 1px -16px, rgb(0 0 0 / 14%) 0px 2px 8px 0px, rgb(0 0 0 / 12%) 0px 2px 8px 0px;
  --padding-bottom: 1rem;
  --padding-top: 1rem;
  height: auto;
  margin: 0.25rem;
}
.view-confirmation-order .current-promo-code {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
}
.view-confirmation-order .current-promo-code ion-card {
  border: 1px solid var(--ion-color-dark);
  border-radius: 1rem;
  flex-grow: 1;
}
.view-confirmation-order .current-promo-code ion-card-content {
  color: var(--ion-color-dark);
  font-weight: 700;
  letter-spacing: 1px;
}
.view-confirmation-order .current-promo-code ion-button {
  --border-radius: 1rem;
  font-size: 1rem;
  margin-left: 0.5rem;
  min-height: 3rem;
}
.view-confirmation-order .submit {
  margin-top: 1rem;
}
.view-confirmation-order .submit ion-button {
  --border-radius: 0.75rem;
  font-size: 1rem;
  min-height: 3rem;
}
