.view-promo-code ion-toolbar > ion-buttons {
  position: absolute;
  left: 0.25rem;
}
.view-promo-code ion-toolbar > ion-title {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}
.view-promo-code ion-title > ion-img {
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-promo-code ion-title > ion-skeleton-text {
  --border-radius: 0.5rem;
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-promo-code ion-label > label {
  color: var(--ion-color-dark);
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-inline: 0.5rem;
}
.view-promo-code ion-input {
  --highlight-color-focused: 0;
  --padding-bottom: 0.75rem;
  --padding-end: 0.75rem;
  --padding-start: 0.75rem;
  --padding-top: 0.75rem;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 1rem;
  color: var(--ion-color-dark);
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition: border-color 150ms ease-in-out;
}
.view-promo-code ion-input.has-focus {
  border-color: var(--ion-color-dark);
  outline: 0;
}
.view-promo-code ion-button {
  --border-radius: 1rem;
  --padding-end: 1.5rem;
  --padding-start: 1.5rem;
  height: 3rem;
  margin-inline: 0.25rem;
  margin-top: 1rem;
}
.view-promo-code .dashed {
  background-image: linear-gradient(to right, var(--ion-color-medium) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 8px 1px;
  margin-block: 1rem;
  margin-inline: 0.5rem;
}
.view-promo-code ion-col > ion-skeleton-text {
  --border-radius: 1.5rem;
  height: 8rem;
}
.view-promo-code ion-item {
  --border-color: var(--ion-color-dark);
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 1.5rem;
  --color: var(--ion-color-dark);
  --inner-padding-bottom: 1rem;
  --inner-padding-end: 1rem;
  --inner-padding-start: 1rem;
  --inner-padding-top: 1rem;
  --min-height: 100%;
  height: 100%;
  border-radius: 1.5rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 8px 1px -16px, rgb(0 0 0 / 14%) 0px 2px 8px 0px, rgb(0 0 0 / 12%) 0px 2px 8px 0px;
}
.view-promo-code ion-item::part(native) {
  align-items: flex-start;
  padding-left: 0;
}
.view-promo-code ion-item.item-disabled {
  --color: var(--ion-color-dark);
  --border-color: var(--ion-color-success);
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 1.5rem;
  --min-height: 100%;
  --inner-padding-bottom: 1rem;
  --inner-padding-end: 1rem;
  --inner-padding-start: 1rem;
  --inner-padding-top: 1rem;
  border-radius: 1.5rem;
  box-shadow: rgb(var(--ion-color-success-rgb), 32%) 0px 4px 2px -8px, rgb(var(--ion-color-success-rgb), 40%) 0px 2px 8px 0px,
    rgb(var(--ion-color-success-rgb), 32%) 0px 2px 8px 0px;
  opacity: 1;
}
.view-promo-code ion-item h3 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}
.view-promo-code ion-item p {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 0.5rem;
}
.view-promo-code .empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(80vh - 10rem);
}
.view-promo-code .empty > ion-img {
  flex-shrink: 0;
  height: 4rem;
  max-width: 100%;
}
@media (min-width: 576px) {
  .view-promo-code ion-col > ion-skeleton-text {
    height: 6.75rem;
  }
  .view-promo-code ion-button {
    margin-left: 0.75rem;
    margin-right: 0;
    margin-bottom: 0.375rem;
  }
}
