.view-splash .container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.view-splash .container > ion-img {
  height: auto;
  margin: auto;
  max-width: 8rem;
}
.view-splash .container > ion-text > h1 {
  color: var(--ion-color-dark);
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0;
  margin-top: 0.5rem;
}
ion-modal#outlet-code-modal {
  --backdrop-opacity: 0.8;
  --border-radius: 0.25rem;
  --height: 100%;
  --max-height: 8.5rem;
  --max-width: calc(100% - 2rem);
  --width: 100%;
}
ion-modal#outlet-code-modal ion-title > h2 {
  font-size: 1rem;
  font-weight: 700;
  margin-block: 0;
}
ion-modal#outlet-code-modal ion-input {
  --border-width: 0;
  --highlight-color-focused: 0;
  --padding-bottom: 0.75rem;
  --padding-end: 0.75rem;
  --padding-start: 0.75rem;
  --padding-top: 0.75rem;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 0.5rem;
  color: var(--ion-color-dark);
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.25rem;
  transition: border-color 150ms ease-in-out;
}
ion-modal#outlet-code-modal ion-input.has-focus {
  border-color: var(--ion-color-dark);
  outline: 0;
}
@media (min-width: 576px) {
  ion-modal#outlet-code-modal {
    --max-width: 24rem;
  }
}
@media (min-width: 768px) {
  .view-splash .container > ion-img {
    max-width: 12rem;
  }
  .view-splash .container > ion-text > h1 {
    font-size: 1.25rem;
  }
}
@media (min-width: 1280px) {
  ion-modal#outlet-code-modal {
    --border-radius: 0.5rem;
  }
  ion-modal#outlet-code-modal ion-input {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
