.view-receipt ion-toolbar > ion-buttons {
  position: absolute;
  left: 0.25rem;
}
.view-receipt ion-toolbar > ion-title {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}
.view-receipt ion-title > ion-img {
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-receipt ion-title > ion-skeleton-text {
  --border-radius: 0.5rem;
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 5rem;
}
.view-receipt ion-content > ion-card {
  margin-block: 1rem;
  margin-inline: 0.5rem;
}
.view-receipt ion-grid {
  --ion-grid-padding: 0;
}
.view-receipt ion-col {
  --ion-grid-column-padding: 0.5rem;
}
.view-receipt .qr-code-wrapper > .qr-code-skeleton {
  --border-radius: 0.5rem;
  min-height: 6rem;
  max-width: 100%;
}
.view-receipt .qr-code-wrapper ion-card {
  border-radius: 0.5rem;
}
.view-receipt .qr-code-wrapper ion-card-content {
  padding: 0.5rem;
}
.view-receipt .qr-code-wrapper .qr-code {
  height: auto;
  max-width: 100%;
  width: 100%;
}
.view-receipt table,
.view-receipt ion-card table {
  color: var(--ion-color-dark);
  font-size: 0.75rem;
  vertical-align: top;
}
.view-receipt tr > td {
  padding-block: 0.25rem;
}
.view-receipt tr > td > ion-skeleton-text {
  --border-radius: 0.25rem;
  height: 0.75rem;
  margin-block: 0;
  padding-block: 0.5rem;
}
.view-receipt ion-text > h1 {
  color: var(--ion-color-dark);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-bottom: 0.25rem;
  margin-top: 0;
}
.view-receipt ion-text > h1 > ion-skeleton-text {
  --border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  max-width: 66%;
  min-height: 0.875rem;
}
.view-receipt ion-text > p {
  color: var(--ion-color-dark);
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0;
}
.view-receipt ion-text > p > ion-skeleton-text {
  --border-radius: 0.25rem;
  min-height: 0.75rem;
  margin-block: 0.375rem;
}
.view-receipt ion-text > p > ion-skeleton-text:nth-child(1) {
  max-width: 90%;
}
.view-receipt ion-text > p > ion-skeleton-text:nth-child(2) {
  max-width: 80%;
}
.view-receipt ion-text > p > ion-skeleton-text:nth-child(3) {
  max-width: 85%;
}
.view-receipt ion-text > p > ion-skeleton-text:nth-child(4) {
  max-width: 75%;
}
.view-receipt .ion-hide-md-up {
  border: 1px solid var(--ion-color-dark);
  border-radius: 1rem;
  margin-inline: 0.5rem;
  padding-block: 0.5rem;
  padding-inline: 1rem;
}
.view-receipt .ion-hide-md-up .number,
.view-receipt .ion-hide-md-up .current,
.view-receipt .queue-desktop .current {
  align-items: center;
  display: flex;
}
.view-receipt .ion-hide-md-up > .number > ion-text:first-child {
  flex-grow: 1;
}
.view-receipt .ion-hide-md-up > .number h2 {
  color: var(--ion-color-dark);
  font-size: 0.875rem;
  font-weight: 700;
  margin-block: 0;
}
.view-receipt .ion-hide-md-up > .number strong {
  color: var(--ion-color-dark);
  font-size: 1.5rem;
}
.view-receipt .ion-hide-md-up > .number ion-skeleton-text {
  --border-radius: 0.25rem;
  min-height: 1.5rem;
  min-width: 3rem;
}
.view-receipt .ion-hide-md-up > .number ion-img {
  flex-shrink: 0;
  height: 1.5rem;
  margin-left: 0.5rem;
  width: 1.5rem;
}
.view-receipt .ion-hide-md-up > .current h3 {
  color: var(--ion-color-dark);
  font-size: 0.75rem;
  font-weight: 700;
  margin-block: 0;
}
.view-receipt .ion-hide-md-up > .current ion-img {
  flex-shrink: 0;
  height: 2.5rem;
  margin-inline: 1rem;
  width: 2.5rem;
}
.view-receipt .ion-hide-md-up > .current > ul {
  flex-grow: 1;
}
.view-receipt .ion-hide-md-up > .current ul {
  flex-grow: 1;
  list-style-type: none;
  margin-block: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-left: 0;
  position: relative;
  scrollbar-width: none;
  width: 50%;
  word-wrap: break-word;
  white-space: nowrap;
  -ms-overflow-style: none;
}
.view-receipt .ion-hide-md-up > .current ul::-webkit-scrollbar {
  display: none;
}
.view-receipt .ion-hide-md-up > .current ul > li {
  color: var(--ion-color-secondary);
  display: inline;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.view-receipt .ion-hide-md-up > .current ul > li > ion-skeleton-text {
  --border-radius: 0.25rem;
  display: inline-block;
  max-width: 1.5rem;
  min-height: 1rem;
}
.view-receipt .ion-hide-md-up > .current ul > li ~ li {
  margin-left: 0.75rem;
}
.view-receipt .dashed {
  background-image: linear-gradient(to right, var(--ion-color-medium) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: center;
  background-repeat: repeat-x;
  background-size: 8px 1px;
}
.view-receipt .queue-desktop {
  display: none;
}
.view-receipt ion-card {
  border-radius: 1rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 1px -8px, rgb(0 0 0 / 14%) 0px 1px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 4px 0px;
}
.view-receipt ion-card table {
  width: 100%;
}
.view-receipt ion-card.total {
  border-radius: 0.5rem;
}
.view-receipt ion-card-content {
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  padding-inline: 1rem;
}
.view-receipt .card-service-skeleton ion-card-content,
.view-receipt .card-product-skeleton ion-card-content {
  padding: 0;
}
.view-receipt .card-service-skeleton ion-skeleton-text,
.view-receipt .card-product-skeleton ion-skeleton-text {
  margin-block: 0;
  min-height: 8rem;
}
.view-receipt .title {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.view-receipt .title ion-img {
  flex-shrink: 0;
  height: 1.25rem;
  margin-right: 0.5rem;
  width: 1.25rem;
}
.view-receipt .title h3 {
  color: var(--ion-color-dark);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-transform: uppercase;
}
.view-receipt .solid {
  background-color: var(--ion-color-medium);
  height: 1px;
  margin-bottom: 0;
  margin-top: 0.25rem;
  width: 100%;
}
@media (min-width: 576px) {
  .view-receipt .qr-code-wrapper > .qr-code-skeleton {
    --border-radius: 0.5rem;
    min-height: 14rem;
  }
  .view-receipt table,
  .view-receipt ion-card table {
    font-size: 0.875rem;
  }
  .view-receipt .qr-code-wrapper ion-card-content {
    padding: 1rem;
  }
  .view-receipt ion-text > h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .view-receipt ion-text > h1 > ion-skeleton-text {
    --border-radius: 0.5rem;
    min-height: 1.5rem;
  }
  .view-receipt ion-text > p {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }
  .view-receipt ion-text > p > ion-skeleton-text {
    --border-radius: 0.5rem;
    min-height: 1rem;
  }
  .view-receipt ion-card-content {
    padding-bottom: 1.25rem;
    padding-top: 0.75rem;
    padding-inline: 1.25rem;
  }
  .view-receipt .title h3 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}
@media (min-width: 768px) {
  .view-receipt .queue-desktop {
    display: block;
  }
  .view-receipt .queue-desktop .queue,
  .view-receipt .queue-desktop .current-list {
    border: 1px solid var(--ion-color-dark);
    border-radius: 1rem;
    padding-block: 0.5rem;
    padding-inline: 1rem;
  }
  .view-receipt .queue-desktop .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .view-receipt .queue-desktop h2,
  .view-receipt .queue-desktop h3 {
    color: var(--ion-color-dark);
    font-size: 1rem;
    font-weight: 700;
    margin-block: 0;
  }
  .view-receipt .queue-desktop h3 {
    padding-block: 0.5rem;
  }
  .view-receipt .queue-desktop .header > ion-img {
    flex-shrink: 0;
    height: 1.75rem;
    margin-left: 0.25rem;
    width: 1.75rem;
  }
  .view-receipt .queue-desktop .badge-skeleton {
    margin-top: 0.5rem;
    min-height: 3rem;
  }
  .view-receipt .queue-desktop .badge {
    background-color: var(--ion-color-light);
    border-radius: 1rem;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-top: 0.5rem;
    padding: 0.25rem;
  }
  .view-receipt .queue-desktop .current > ion-img {
    flex-shrink: 0;
    height: 2rem;
    margin-right: 1rem;
    width: 2rem;
  }
  .view-receipt .queue-desktop ul {
    flex-grow: 1;
  }
  .view-receipt .queue-desktop ul {
    flex-grow: 1;
    list-style-type: none;
    margin-block: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 0;
    position: relative;
    scrollbar-width: none;
    width: 50%;
    word-wrap: break-word;
    white-space: nowrap;
    -ms-overflow-style: none;
  }
  .view-receipt .queue-desktop ul::-webkit-scrollbar {
    display: none;
  }
  .view-receipt .queue-desktop ul > li {
    color: var(--ion-color-secondary);
    display: inline;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .view-receipt .queue-desktop ul > li > ion-skeleton-text {
    --border-radius: 0.5rem;
    display: inline-block;
    max-width: 1.75rem;
    min-height: 1.25rem;
  }
  .view-receipt .queue-desktop ul > li ~ li {
    margin-left: 0.75rem;
  }
}
