.view-home ion-header ion-title {
  height: auto;
  max-width: 100%;
  width: 7rem;
}
.view-home ion-header h1 {
  color: var(--ion-color-dark);
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-block: 0;
  min-width: 40vw;
}
.view-home ion-header ion-title > ion-skeleton-text {
  --border-radius: 0.5rem;
  height: 2.5rem;
}
.view-home ion-header h1 > ion-skeleton-text {
  --border-radius: 0.25rem;
  height: 1.25rem;
}
.view-home ion-segment {
  margin-bottom: 1rem;
  border-radius: 1rem 1rem 0 0;
}
.view-home ion-segment-button {
  --background-focused: var(--ion-color-light-shade);
  --background-hover: var(--ion-color-light-shade);
  --color: var(--ion-color-dark);
  --color-checked: var(--ion-color-dark);
  --indicator-color: var(--ion-color-dark);
  font-weight: 700;
}
.view-home ion-segment-button > ion-img {
  flex-shrink: 0;
  height: 1.25rem;
  margin-right: 0.25rem;
  width: 1.25rem;
}
.view-home .dashed {
  background-image: linear-gradient(to right, var(--ion-color-medium) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: center;
  background-repeat: repeat-x;
  background-size: 8px 1px;
  margin-bottom: 1rem;
  margin-top: 0;
}
.view-home .submit ion-button {
  --border-radius: 0.75rem;
  font-size: 1rem;
  min-height: 3rem;
}
.view-home .solid {
  background-color: var(--ion-color-medium);
  height: 1px;
  margin-bottom: 0;
  margin-top: 1rem;
  width: 100%;
}
.view-home .sticky-top {
  height: 100%;
  position: sticky;
  top: 0;
}
.view-home .customers {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1.5rem;
}
.view-home .customers ion-button {
  --padding-bottom: 0.5rem;
  --padding-end: 0.5rem;
  --padding-start: 0.5rem;
  --padding-top: 0.5rem;
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: 0;
}
ion-modal#modal-home {
  --backdrop-opacity: 0.8;
  --border-radius: 0;
}
ion-modal#modal-home h2 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-block: 0;
}
ion-modal#modal-home .dashed {
  background-image: linear-gradient(to right, var(--ion-color-medium) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: center;
  background-repeat: repeat-x;
  background-size: 8px 1px;
  margin-block: 0;
}
ion-modal#modal-home ion-segment-button {
  --background-focused: var(--ion-color-light-shade);
  --background-hover: var(--ion-color-light-shade);
  --color: var(--ion-color-dark);
  --color-checked: var(--ion-color-dark);
  --indicator-color: var(--ion-color-dark);
  font-weight: 700;
}
ion-modal#modal-home ion-segment-button > ion-img {
  flex-shrink: 0;
  height: 1.25rem;
  margin-right: 0.25rem;
  width: 1.25rem;
}
ion-modal#modal-home ion-button {
  --padding-bottom: 0.5rem;
  --padding-end: 0.5rem;
  --padding-start: 0.5rem;
  --padding-top: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  right: 0.5rem;
}
ion-modal#modal-home .skeleton {
  padding: 1rem;
  position: relative;
}
ion-modal#modal-home .skeleton > ion-skeleton-text {
  --border-radius: 1rem;
  height: 8rem;
}
ion-modal#modal-home .empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 10rem);
}
ion-modal#modal-home .empty > ion-img {
  flex-shrink: 0;
  height: 4rem;
  max-width: 100%;
}
ion-modal#modal-home ion-item {
  --ripple-color: transparent;
  --padding-bottom: 0.75rem;
  --padding-top: 0.75rem;
  border-radius: 1rem;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 1px -8px, rgb(0 0 0 / 12%) 0px 1px 4px 0px, rgb(0 0 0 / 10%) 0px 1px 4px 0px;
  margin-block: 0.5rem;
}
ion-modal#modal-home ion-chip {
  --background: var(--ion-color-secondary-shade);
  --color: #ffffff;
  font-size: 0.875rem;
  font-weight: 700;
  left: 2rem;
  position: absolute;
  top: -0.5rem;
  z-index: 2;
}
ion-modal#modal-home ion-thumbnail {
  align-items: center;
  border: 1px solid var(--ion-color-dark);
  border-radius: 0.5rem;
  display: flex;
  flex-shrink: 0;
  height: auto;
  justify-content: center;
  margin-right: 1rem;
  max-width: 5rem;
  padding: 0.5rem;
  width: 100%;
}
ion-modal#modal-home .qr-code {
  height: auto;
  max-width: 100%;
}
ion-modal#modal-home ul {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  list-style: none;
  margin-block: 0;
  padding-left: 0;
}
ion-modal#modal-home li {
  display: flex;
  font-size: 0.75rem;
  line-height: 1.25rem;
  justify-content: space-between;
}
ion-modal#modal-home li > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ion-modal#modal-home ion-infinite-scroll {
  align-items: center;
  display: flex;
  justify-content: center;
}
@media (min-width: 576px) {
  .view-home ion-header h1 {
    min-width: 30vw;
  }
  .view-home .customers {
    column-gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 1.5rem;
  }
}
@media (min-width: 768px) {
  .view-home ion-header h1 {
    min-width: 25vw;
  }
  .view-home ion-segment {
    margin-top: -0.75rem;
    max-width: calc(100% - 0.5rem);
  }
  .view-home ion-segment-button,
  ion-modal#modal-home ion-segment-button {
    max-width: 100%;
  }
  .view-home .customers {
    column-gap: 0;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 1.5rem;
  }
  ion-modal#modal-home {
    --border-radius: 1rem;
    --min-height: calc(80vh - 8rem);
    --min-width: calc(100vw - 4rem);
  }
  ion-modal#modal-home .empty {
    min-height: calc(80vh - 16rem);
  }
  ion-modal#modal-home ion-item {
    margin-inline: 0.5rem;
  }
  ion-modal#modal-home ion-chip {
    left: 2.5rem;
  }
}
@media (max-height: 576px) and (min-width: 768px) {
  ion-modal#modal-home {
    --border-radius: 0;
  }
  ion-modal#modal-home .empty {
    min-height: calc(100vh - 10rem);
  }
}
@media (min-width: 1024px) {
  .view-home ion-header h1 {
    min-width: 20vw;
  }
}
