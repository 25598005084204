.component-cart-items ion-item {
  --background-focused: transparent;
  --background-hover: transparent;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --ripple-color: transparent;
  align-items: flex-start;
  display: flex;
}
.component-cart-items ion-checkbox {
  --checkbox-background: var(--ion-color-light-shade);
  --border-color: transparent;
  --border-color-checked: transparent;
  --border-radius: 0.5rem;
  --size: 1.5rem;
  margin-block: 1.5rem;
  margin-inline-end: 0.5rem;
  margin-inline-start: 0;
}
.component-cart-items .check-box {
  --border-radius: 0.5rem;
  height: 1.5rem;
  margin-block: 1.5rem;
  margin-inline-end: 0.5rem;
  margin-inline-start: 0;
  max-width: 1.5rem;
}
.component-cart-items ion-card {
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 8px 2px -16px, rgb(0 0 0 / 14%) 0px 2px 8px 0px, rgb(0 0 0 / 12%) 0px 2px 8px 0px;
}
.component-cart-items ion-card-content {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.component-cart-items ion-img {
  flex-shrink: 0;
  height: 4.5rem;
  margin-right: 1rem;
  max-width: 100%;
}
.component-cart-items ion-img::part(image) {
  border-radius: 0.5rem;
}
.component-cart-items .image {
  --border-radius: 0.5rem;
  flex-shrink: 0;
  height: 4.5rem;
  margin-right: 1rem;
  max-width: 4.5rem;
}
.component-cart-items .label,
.component-cart-items h3,
.component-cart-items strong,
.component-cart-items p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.component-cart-items .label {
  flex-grow: 1;
}
.component-cart-items h3 {
  color: var(--ion-color-dark-tint);
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5rem;
}
.component-cart-items h3 > ion-skeleton-text {
  --border-radius: 0.25rem;
  height: 0.875rem;
  max-width: 80%;
}
.component-cart-items strong {
  color: var(--ion-color-dark);
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.25rem;
  margin-top: 0.5rem;
}
.component-cart-items strong > ion-skeleton-text {
  --border-radius: 0.25rem;
  display: block;
  height: 0.875rem;
  max-width: 60%;
}
.component-cart-items p {
  color: var(--ion-color-dark-tint);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}
.component-cart-items p > ion-skeleton-text {
  --border-radius: 0.25rem;
  height: 0.75rem;
}
.component-cart-items ion-row {
  padding-block: 0;
  margin-bottom: 1rem;
}
.component-cart-items ion-col ion-text > p {
  color: var(--ion-color-danger);
  font-weight: 700;
  margin-right: 0.25rem;
  text-align: right;
}
.component-cart-items ion-button {
  --border-radius: 0.5rem;
  --padding-bottom: 0.5rem;
  --padding-end: 0.5rem;
  --padding-start: 0.5rem;
  --padding-top: 0.5rem;
  min-height: 2rem;
  min-width: 2rem;
}
.component-cart-items .button {
  --border-radius: 0.5rem;
  min-height: 2rem;
  min-width: 2rem;
}
.component-cart-items ion-input {
  --border-width: 0;
  --highlight-color-focused: 0;
  --padding-bottom: 0.375rem;
  --padding-end: 0.375rem;
  --padding-start: 0.375rem;
  --padding-top: 0.375rem;
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 1px -8px, rgb(0 0 0 / 14%) 0px 1px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 4px 0px;
  color: var(--ion-color-dark);
  text-align: center;
}
.component-cart-items .input {
  border-radius: 0.5rem;
  min-height: 2rem;
  min-width: 4.5rem;
}
.component-cart-items .empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 32vh;
}
.component-cart-items .empty > ion-img {
  flex-shrink: 0;
  height: 3.5rem;
  margin-top: 1rem;
  width: 3.5rem;
}
.component-cart-items .empty p {
  color: var(--ion-color-medium);
  font-weight: 700;
  margin-top: 0.5rem;
}
@media (min-width: 576px) {
  .component-cart-items h3 > ion-skeleton-text {
    max-width: 60%;
  }
  .component-cart-items strong > ion-skeleton-text {
    max-width: 40%;
  }
  .component-cart-items p > ion-skeleton-text {
    max-width: 80%;
  }
  .component-cart-items ion-col ion-text > p {
    margin-right: 1rem;
  }
}
@media (min-width: 768px) {
  .component-cart-items ion-img::part(image) {
    border-radius: 0.75rem;
  }
  .component-cart-items .image {
    --border-radius: 0.75rem;
  }
  .component-cart-items .image {
    --border-radius: 0.75rem;
  }
  .component-cart-items h3 > ion-skeleton-text {
    max-width: 50%;
  }
  .component-cart-items strong > ion-skeleton-text {
    max-width: 30%;
  }
  .component-cart-items p > ion-skeleton-text {
    max-width: 70%;
  }
}
