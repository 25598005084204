.component-payment-detail {
  color: var(--ion-color-dark);
  vertical-align: top;
  width: 100%;
}
.component-payment-detail th {
  padding-block: 0.25rem;
  padding-inline: 0.5rem;
}
.component-payment-detail td {
  padding: 0.5rem;
}
.component-payment-detail .date {
  color: var(--ion-color-medium);
}
.component-payment-detail .dashed > td {
  background-image: linear-gradient(to right, var(--ion-color-medium) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 8px 1px;
  margin-bottom: 1rem;
  margin-inline: 1rem;
  margin-top: 0;
  padding-top: 0;
}
