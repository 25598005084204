.component-hairstylists {
  margin-bottom: 0.75rem;
  margin-top: 1.5rem;
}
.component-hairstylists h2 {
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-block: 0;
}
.component-hairstylists ion-col {
  padding-inline: 0;
}
.component-hairstylists ion-item {
  --inner-padding-end: 0;
  align-items: center;
  display: flex;
}
.component-hairstylists ion-item::part(native) {
  align-items: flex-start;
  padding-left: 0;
}
.component-hairstylists ion-item > .item-bottom {
  padding-inline-start: 0;
}
.component-hairstylists ion-thumbnail {
  --border-radius: 0.5rem;
  flex-shrink: 0;
  height: 6rem;
  width: 6rem;
}
.component-hairstylists h3,
.component-hairstylists h4,
.component-hairstylists strong {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-block: 0;
}
.component-hairstylists h3 > ion-skeleton-text,
.component-hairstylists h4 > ion-skeleton-text,
.component-hairstylists strong > ion-skeleton-text {
  --border-radius: 0.25rem;
  height: 0.875rem;
}
.component-hairstylists h3,
.component-hairstylists h4 {
  color: var(--ion-color-medium-shade);
}
.component-hairstylists h4 > ion-skeleton-text {
  max-width: 80%;
}
.component-hairstylists strong {
  display: block;
  flex-grow: 1;
  margin-top: 0.125rem;
  line-height: 1.5rem;
}
.component-hairstylists strong > ion-skeleton-text {
  max-width: 60%;
}
.component-hairstylists ion-label {
  display: flex;
  flex-direction: column;
}
.component-hairstylists ion-label ion-img {
  flex-shrink: 0;
  height: 1rem;
  margin-right: 0.5rem;
  width: 1rem;
}
.component-hairstylists p {
  color: var(--ion-color-medium);
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.component-hairstylists p > ion-skeleton-text {
  --border-radius: 0.25rem;
  height: 0.75rem;
  max-width: 85%;
}
.component-hairstylists .empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 32vh;
}
.component-hairstylists .empty > ion-img {
  flex-shrink: 0;
  height: 3rem;
  margin-block: 1rem;
  width: 3rem;
}
.component-hairstylists .empty p {
  color: var(--ion-color-medium);
  font-weight: 700;
  margin-top: 0.5rem;
}
@media (min-width: 576px) {
  .component-hairstylists ion-item {
    --inner-padding-end: 1rem;
  }
  .component-hairstylists h3,
  .component-hairstylists h4,
  .component-hairstylists strong,
  .component-hairstylists p {
    font-size: 0.75rem;
  }
  .component-hairstylists h3 > ion-skeleton-text {
    max-width: 80%;
  }
  .component-hairstylists h4 > ion-skeleton-text {
    max-width: 60%;
  }
  .component-hairstylists strong > ion-skeleton-text {
    max-width: 40%;
  }
  .component-hairstylists .empty {
    min-height: 40vh;
  }
}
@media (min-width: 768px) {
  .component-hairstylists h3 > ion-skeleton-text {
    max-width: 90%;
  }
  .component-hairstylists h4 > ion-skeleton-text {
    max-width: 70%;
  }
  .component-hairstylists strong > ion-skeleton-text {
    max-width: 50%;
  }
  .component-hairstylists p > ion-skeleton-text {
    max-width: 100%;
  }
  .component-hairstylists .empty {
    min-height: 24vh;
  }
}
@media (min-width: 1024px) {
  .component-hairstylists h3 > ion-skeleton-text {
    max-width: 60%;
  }
  .component-hairstylists h4 > ion-skeleton-text {
    max-width: 40%;
  }
  .component-hairstylists strong > ion-skeleton-text {
    max-width: 20%;
  }
  .component-hairstylists p > ion-skeleton-text {
    max-width: 80%;
  }
  .component-hairstylists .empty {
    min-height: 32vh;
  }
}
