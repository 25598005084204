.component-customer-list > ion-text {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.component-customer-list h2 {
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-block: 0;
}
.component-customer-list > ul {
  border-radius: 0.5rem;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0.5rem;
  overflow-x: hidden;
  padding: 1rem;
  position: relative;
}
.component-customer-list > .normal {
  border: 1px solid var(--ion-color-medium);
}
.component-customer-list > .active {
  border: 1px solid var(--ion-color-secondary);
  min-height: 8rem;
}
.component-customer-list li {
  color: var(--ion-color-dark);
  font-size: 0.875rem;
  font-weight: 700;
}
.component-customer-list li p {
  font-weight: 400;
  margin-top: 0.375rem;
}
.component-customer-list li + li {
  margin-top: 1rem;
}
.component-customer-list li ion-skeleton-text {
  --border-radius: 0.25rem;
  min-height: 0.875rem;
}
.component-customer-list .empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 10rem;
}
.component-customer-list .empty > ion-img {
  flex-shrink: 0;
  height: 3rem;
  margin-top: 1rem;
  width: 3rem;
}
.component-customer-list .empty p {
  color: var(--ion-color-medium);
  font-weight: 700;
}
@media (min-width: 576px) {
  .component-customer-list > .active {
    max-height: 55vh;
  }
}
@media (min-width: 768px) {
  .component-customer-list > .active {
    min-height: calc(34vh - 6rem);
  }
  .component-customer-list .empty {
    min-height: calc(34vh - 8rem);
  }
  .component-customer-list li {
    font-size: 0.75rem;
  }
}
@media (min-width: 1024px) {
  .component-customer-list li {
    font-size: 0.875rem;
  }
}
